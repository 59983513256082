import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'sana/texts';
import styles from './CustomPaging.module.scss';
import { urlEscape } from '../../../utils/helpers';

const CustomPaging = ({
  activeDotIndex,
  currentIndex,
  onClick,
  navigatorIcon,
  navigatorIconHover,
  numbersOfImages,
}) => {
  const [hover, toggleHover] = useState(false);

  if (hover) {
    navigatorIcon = navigatorIconHover;
  }

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      className={styles.dotStyle}
      onMouseEnter={() => toggleHover(!hover)}
      onMouseLeave={() => toggleHover(!hover)}
    >
      <span className="sr-only">
        <SimpleText textKey="DotNavigatorItem" />
        {currentIndex + 1}  
        {activeDotIndex/numbersOfImages === currentIndex && <SimpleText textKey="CurrentSlide" />}
      </span>

      <img
        src={
          activeDotIndex/numbersOfImages === currentIndex
            ? urlEscape(navigatorIconHover)
            : urlEscape(navigatorIcon)
        }
        alt={currentIndex + 1}
        aria-hidden
      />
    </div>
  );
};

CustomPaging.propTypes = {
  activeDotIndex: PropTypes.number,
  currentIndex: PropTypes.number,
  onClick: PropTypes.func,
  navigatorIcon: PropTypes.string,
  navigatorIconHover: PropTypes.string,
  numbersOfImages:PropTypes.number,
};

export default CustomPaging;
