import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getImageScalingClass } from './../../../utils/constants';
import styles from './Image.module.scss';
import { LazyImage } from 'sana/elements';
const Image = ({
  isMobile,
  imagePath,
  mobileImagePath,
  imageAlterText,
  mobileImageAlterText,
  spaceBetweenImages,
  imageScalingOption,
  afterLoad,
  visibleByDefault,
}) => {
  const src = isMobile && mobileImagePath ? mobileImagePath : imagePath;
  const alt =
    isMobile && mobileImagePath && mobileImageAlterText
      ? mobileImageAlterText
      : imageAlterText;
  const className = classNames(
    styles.image,
    styles[getImageScalingClass(imageScalingOption)],
  );
  const style = {
    padding: spaceBetweenImages && `0 ${spaceBetweenImages}px`,
  };
  if (visibleByDefault)
    return <img src={src} alt={alt} className={className} style={style} />;

  return (
    <LazyImage
      src={src}
      alt={alt}
      className={className}
      afterLoad={afterLoad}
      style={style}
    />
  );
};

Image.propTypes = {
  isMobile: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  mobileImagePath: PropTypes.string,
  imageAlterText: PropTypes.string.isRequired,
  mobileImageAlterText: PropTypes.string,
  spaceBetweenImages: PropTypes.number,
  imageScalingOption: PropTypes.number,
  afterLoad: PropTypes.func,
  visibleByDefault: PropTypes.bool,
};

export default Image;
