exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.33.33\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".ImageSliderContentBlock__TitleVariant_text-wrapper *{color:inherit}", ""]);

// exports
exports.locals = {
	"text-wrapper": "ImageSliderContentBlock__TitleVariant_text-wrapper",
	"textWrapper": "ImageSliderContentBlock__TitleVariant_text-wrapper"
};